import React, { useState, useEffect } from "react";

import {
  currentUserVar,
  UserPoolClient,
  useQuery,
  useReactiveVar,
} from "@apollo";
import { USER_POOL_GET_USER_OFFERS } from "@queries";

// Misc Imports
import { StaticOffer, Theme } from "@app-types";
import { useMediaQuery, useOffers } from "@hooks";
import { useTranslation } from "@i18n";
import { Offer } from "@models";
import { makeStyles } from "@styles";
import { navigate } from "@utils";

// Component imports
import {
  DialogButton,
  Link,
  WhatILikeCardsContainer,
  WhatILikeFilters,
} from "@components";
import { Grid, Typography } from "@material";
import { HeartSaveIcon } from "@icons";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    flexGrow: 1,
    width: "80%",
    maxWidth: 1400,
    marginLeft: "auto",
    marginRight: "auto",
    marginTop: 16,
    minHeight: 635,
    [theme.breakpoints.down("md")]: {
      width: "90%",
      marginTop: 8,
    },
  },
  headerContainer: {
    marginBottom: 26,
  },
  headerText: {
    color: "black",
    fontWeight: 900,
    fontSize: 42,
    [theme.breakpoints.down("xs")]: {
      fontSize: 32,
    },
  },
  offerColumnContainer: {
    display: "grid",
    gridTemplateColumns: "250px auto",
    gridColumnGap: 45,
  },
  filtersColumnContainer: {
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  filtersColumn: {
    [theme.breakpoints.up("md")]: {
      position: "sticky",
      top: 78,
      maxWidth: 250,
    },
  },
  noUserOffersContainer: {
    height: 510,
    width: "100%",
    backgroundColor: "white",
    border: `1px solid #EBEBEB`,
    padding: 24,
    minHeight: 411,
    borderRadius: 4,
    marginBottom: 17,
    [theme.breakpoints.down("xs")]: {
      height: "auto",
    },
  },
  cardContainer: {
    [theme.breakpoints.down("sm")]: {
      marginTop: 16,
    },
  },
  noUserOffersInnerContainer: {
    width: 450,
    textAlign: "center",
    [theme.breakpoints.down("sm")]: {
      widthPercentageToDP: "auto",
    },
    [theme.breakpoints.down("xs")]: {
      width: "auto",
    },
  },
  heartIconContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "50%",
    width: 175,
    height: 175,
    backgroundColor: "#FAFAFA",
    marginBottom: 24,
    border: `1px solid #EBEBEB`,
  },
  noUserOffersHeader: { fontSize: 20, fontWeight: 500, marginBottom: 8 },
  noUserOffersSubheader: { fontSize: 18, fontWeight: 400, marginBottom: 12 },
  loadingSpinnerContainer: {
    height: 400,
  },
}));

export default function UserOffersScreen() {
  const classes: any = useStyles({});
  const small: boolean = useMediaQuery("(max-width:959px)");
  const { t } = useTranslation(["whatILike", "common"]);

  const user = useReactiveVar(currentUserVar);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (!user) navigate("/sign-in", { replace: true });
  }, []);

  const [category, setCategory] = useState<string>("All Offers");
  const [order, setOrder] = useState<string>("Featured");

  const { data } = useQuery<{ userOffers: Offer[] }>(
    USER_POOL_GET_USER_OFFERS,
    {
      client: UserPoolClient,
      fetchPolicy: "cache-only",
      variables: { id: user?.id },
      onError: (err) => console.warn(`Error fetching user offer IDs: ${err}`),
    },
  );

  const offers: StaticOffer[] = useOffers();

  const userOfferIds = data?.userOffers?.map((offer) => offer.id) || undefined;

  const userOffersToRender: StaticOffer[] =
    offers &&
    userOfferIds &&
    offers.filter(
      (offer: StaticOffer): StaticOffer => {
        if (userOfferIds.includes(offer.id)) {
          return offer;
        } else {
          return null;
        }
      },
    );

  return !user ? null : (
    <>
      <div className={classes.root}>
        <Grid
          item
          container
          xs={12}
          md={6}
          direction="column"
          justify="flex-start"
          alignItems="flex-start"
        >
          <Grid
            item
            container
            direction="column"
            className={classes.headerContainer}
          >
            <Typography className={classes.headerText}>
              {t("common:whatILike")}
            </Typography>
          </Grid>
        </Grid>
        <Grid
          container
          item
          xs={12}
          className={`${!small && classes.offerColumnContainer}`}
        >
          <Grid item sm={12} md className={classes.filtersColumnContainer}>
            <div className={classes.filtersColumn}>
              <WhatILikeFilters
                category={category}
                setCategory={setCategory}
                order={order}
                setOrder={setOrder}
              />
            </div>
          </Grid>
          <Grid
            container
            item
            direction="row"
            justify="space-between"
            alignItems="flex-start"
            sm={12}
            md
            className={classes.cardContainer}
          >
            {userOffersToRender?.length ? (
              <WhatILikeCardsContainer
                category={category}
                offers={userOffersToRender}
                order={order}
                userId={user?.id}
              />
            ) : (
              <>
                <Grid
                  container
                  item
                  direction="column"
                  alignItems="center"
                  justify="center"
                  className={classes.noUserOffersContainer}
                >
                  <Grid
                    container
                    direction="column"
                    alignItems="center"
                    justify="center"
                    className={classes.noUserOffersInnerContainer}
                  >
                    <Grid className={classes.heartIconContainer}>
                      <HeartSaveIcon width={150} />
                    </Grid>
                    <Typography className={classes.noUserOffersHeader}>
                      {t("noUserOffersHeader")}
                    </Typography>
                    <Typography className={classes.noUserOffersSubheader}>
                      {t("noUserOffersSubheader")}
                    </Typography>
                    <Link to="/">
                      <DialogButton
                        colorVariant="black"
                        width={232}
                        height={47}
                        borderRadius={0}
                      >
                        {t("keepExploring")}
                      </DialogButton>
                    </Link>
                  </Grid>
                </Grid>
              </>
            )}
          </Grid>
        </Grid>
      </div>
    </>
  );
}
